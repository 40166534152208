import { Component, OnInit } from "@angular/core";
import { NavigationService } from "@app/core/services/navigation.service";
import { PermissionsService } from "@app/core/services/permissions.service";
import { BehaviorSubject } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { AppsListService } from "@app/modules/my-apps/apps-list/apps-list.service";

@Component({
    selector: "app-mobile-navigation-bar",
    templateUrl: "./mobile-navigation-bar.component.html",
    styleUrls: ["./mobile-navigation-bar.component.scss"],
})
export class MobileNavigationBarComponent implements OnInit {
    showStatistics: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showMyApps: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showMyWallet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isMyAppsRoute: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(public router: Router, private navigationService: NavigationService, private permissionService: PermissionsService, public appListService: AppsListService) {
        this.showStatistics.next(this.permissionService.checkMenuItemDashboardPermissions());
        this.showMyApps.next(this.permissionService.checkMenuItemMyAppsPermissions());
        this.showMyWallet.next(this.permissionService.checkMenuItemWalletPermissions());
    }

    ngOnInit() {
        this.isMyAppsRoute.next(this.checkIfIsMyAppsRoot(this.router.url));
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isMyAppsRoute.next(this.checkIfIsMyAppsRoot(event.url));
            }
        });
    }

    checkIfIsMyAppsRoot(url: string): boolean {
        return url === "/myApps";
    }

    navigateTo(route) {
        this.navigationService.closeAllNavigationDropdowns();
        this.router.navigate([route]);
    }
}
