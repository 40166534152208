<div
    [class.disabled]="disabled"
    [class.white]="white"
    [class.gray]="gray"
    [class.roundedBorders]="roundedBorders"
    [class.veryRoundedBorders]="veryRoundedBorders"
    [class.mobileFilter]="mobileFilter"
    [class.attachToElementOnLeft]="attachToElementOnLeft"
    [class.noBorderRadiusLeft]="noBorderRadiusLeft"
    [class.noMaxWidth]="noMaxWidth"
    [class.big]="big"
    [class.veryBig]="veryBig"
    [class.small]="small"
    [class.withMinWidth]="withMinWidth"
    [class.withSmallMinWidth]="withSmallMinWidth"
    [class.withMediumMinWidth]="withMediumMinWidth"
    [class.withBigMinWidth]="withBigMinWidth"
    [class.withSmallMaxWidth]="withSmallMaxWidth"
    [class.withMediumMaxWidth]="withMediumMaxWidth"
    (click)="toggle()"
    class="catappult-select"
>
    <div class="select-label" *ngIf="showLabel && label">
        <p class="veryVerySmall pink defaultLineHeight uppercase">{{ label | translate }}:<span *ngIf="mandatory">*</span></p>
    </div>
    <mat-select
        [class.keepSmallLabelFontOnMobile]="keepSmallLabelFontOnMobile"
        disableOptionCentering
        (selectionChange)="changeSelectedOption($event)"
        [disabled]="disabled"
        #selectElement
        [panelClass]="mobilePanel ? 'select-dropdown-mobile' + (panelRightToTheLeft ? ' rightToLeft' : '') : 'select-dropdown' + (panelRightToTheLeft ? ' rightToLeft' : '')"
        [value]="selectedOption ? selectedOption.value : null"
    >
        <mat-option *ngFor="let option of options" [value]="option.value">
            <span class="almostBlack veryVerySmall normal inputFontMobileSize">{{ option.label | translate }}</span>
        </mat-option>
    </mat-select>
    <ng-container *ngIf="!hideArrow">
        <img *ngIf="white || gray" (click)="toggle()" [class.disabled]="disabled" class="arrow" src="/assets/images/arrow-orange.svg" />
        <img *ngIf="!gray && !white" (click)="toggle()" [class.disabled]="disabled" class="arrow" src="/assets/images/arrow.svg" />
    </ng-container>
</div>
