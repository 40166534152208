export const environment = {
    baseNodeUrlServices: "https://ws.catappult.io",
    baseUploaderNodeUrlServices: "https://uploader.catappult.io",
    basePortalUrl: "https://connect.aptoide.com",
    authorizationTokenCookieName: "APTOIDE-CONNECT-TOKEN",
    clientKey: "live_CURXTJUT7RDSZI4JPZXPNYEYXAGBJ2BF",
    domainCookie: ".connect.aptoide.com",
    environment: "live",
    local: false,
    name: "production",
    production: true,
    timestamp: "1577105009578",
    adminConsoleUrl: "https://admin.connect.aptoide.com",
    partnersConsoleUrl: "https://partner.connect.aptoide.com",
    withdrawUnderMaintenance: false,
    uploaderUnderMaintenance: false,
    directLandingUrl: "https://direct.connect.aptoide.com",
    aptoideMmp: "https://aptoide-mmp.aptoide.com/api/v1",
    indicativeEventsPrefix: "connect_",
    chinaHostname: "catappult.cn",
    documentationHostname: "https://docs.catappult.io/docs/",
    documentationReferenceHostname: "https://docs.catappult.io/reference/",
    flagsUrlPrefix: "https://developer.connect.aptoide.com/assets/images/flags/",
    enableTranslations: false,
};
