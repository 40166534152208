import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { getDomainCookie, getPortalUrl } from "./utils";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class UserService {
    loggedUser = new BehaviorSubject<any>({});
    loggedAccount = new BehaviorSubject<any>({});
    selectedLanguageOption = new BehaviorSubject<any>({});
    welcomeSettingsOpened = new BehaviorSubject<boolean>(false);
    _isRecoverableEmail = new Subject<any>();
    _lastIntercomUpdate: any;
    loadingUserBasicInformation = new BehaviorSubject<boolean>(true);
    requiresPasswordValidation = new BehaviorSubject<boolean>(true);
    languageChanged = new BehaviorSubject<boolean>(false);
    selectedAccountUid: string;
    selectedAccountName: string;

    constructor(private http: HttpClient, private _cookieService: CookieService, private translateService: TranslateService) {}

    loadUserBasicInformation() {
        this.loadingUserBasicInformation.next(true);
        const url = environment.baseNodeUrlServices + "/api/session?nocache";
        this.http.get(url).subscribe({
            next: (resp: any) => {
                const _user = resp.user;
                const _account = resp.account;
                const _accountUser = resp.accountUser;
                const _admin = resp?.admin;
                this.selectedAccountUid = _account.uid;
                this.selectedAccountName = _account.name;

                if (_account.isDeveloper && !_account.settings["developer.distributionPolicy"] && !_account.settings["developer.distributionPolicyPhysicallySigned"]) {
                    window.location.href = getPortalUrl();
                } else {
                    let user = {
                        permissions: _accountUser.permissions,
                        allowedPackagesName: _accountUser.packageNames,
                        roles: _accountUser.roles,
                        name: _user.name,
                        email: _user.emailAddress,
                        origin: _user.origin,
                        username: _user.emailAddress,
                        phoneNumber: _user.phoneNumber,
                        signupTimestamp: _account ? _account.signupTimestamp : "",
                        contactEmail: _user.settings.contactEmailAddress,
                        loginConfirmationMethod: _user.settings.loginConfirmationMethod,
                        weChatUid: _user.settings.weChatUid,
                        withdrawRequestConfirmationMethod: _user.settings.withdrawRequestConfirmationMethod,
                        currency: _user.settings.currency ? _user.settings.currency : "USD",
                        uid: _user.uid,
                        accountUid: _account.uid,
                        type: _account.isDeveloper ? "developer" : "store",
                        isDeveloper: _account.isDeveloper,
                        withdrawInfo: {
                            recipientWalletAddress: _user.settings["withdrawAppc.wallet"],
                            accountHolderCountry: _user.settings["withdrawFiat.holderCountry"],
                            accountHolderAddress: _user.settings["withdrawFiat.holderAddress"],
                            accountHolderZipCode: _user.settings["withdrawFiat.holderZipCode"],
                            accountHolderState: _user.settings["withdrawFiat.holderState"],
                            accountHolderName: _user.settings["withdrawFiat.holderName"],
                            accountHolderCity: _user.settings["withdrawFiat.holderCity"],
                            accountCurrency: _user.settings["withdrawFiat.currency"],
                            bicSwiftCode: _user.settings["withdrawFiat.bic"],
                            iban: _user.settings["withdrawFiat.iban"],
                            paypalAccount: _user.settings["withdrawFiat.paypalAccount"],
                            vat: _user.settings.vat,
                        },
                    };
                    window.localStorage.setItem("usedLanguage", _user.settings.locale ? _user.settings.locale : "en_GB");
                    if (environment.enableTranslations) {
                        this.translateService.setDefaultLang(window.localStorage.getItem("usedLanguage"));
                        this.translateService.use(window.localStorage.getItem("usedLanguage")).subscribe();
                    }
                    _account.settings.activeSubscription = _account.activeSubscription;
                    _account.settings.deletionRequest = _account.deletionRequest;
                    _account.settings.ownerUserEmailAddress = _account.ownerUser.emailAddress;
                    _account.settings.isCopublisher = _accountUser.roles.some((r) => r.name === "copublisher");
                    _account.settings.AcceptedCopublisherTerms = _accountUser.settings["copublisher.distributionPolicy"];
                    this.requiresPasswordValidation.next(!["google", "facebook"].includes(_user.origin));

                    this.loggedUser.next(user);

                    _account.settings.uid = _account.uid;
                    _account.settings.hasApplications = _account.hasApplications;
                    _account.settings.hasApplicationsLive = _account.hasApplicationsLive;
                    _account.settings.hasApplicationsWithBillingLive = _account.hasApplicationsWithBillingLive;
                    _account.settings.isEligibleForFreeSubscription = _account.isEligibleForFreeSubscription;

                    this.loggedAccount.next(_account.settings);
                }

                this.loadingUserBasicInformation.next(false);
            },
            error: (error: any) => {
                this.loadingUserBasicInformation.next(false);

                if (error.status === 403) {
                    window.location.href = getPortalUrl();
                }
            },
        });
    }

    editAccount(settingName: string, state: boolean): Observable<any> {
        const data: any = {};
        data[settingName] = state;
        return this.http.patch(`${environment.baseNodeUrlServices}/api/accounts/${this.loggedAccount?.value?.uid}`, data);
    }

    editAccountLocally(settingName: string, state: boolean) {
        const loggedAccount = this.loggedAccount?.value;
        loggedAccount[settingName] = state;
        this.loggedAccount.next(loggedAccount);
    }

    toggleSelfDistribution(state: boolean) {
        this.editAccount("developer.isSelfDistributor", state).subscribe({
            next: () => {
                this.editAccountLocally("developer.isSelfDistributor", state);
            },
        });
    }

    hideFaureciaBanner() {
        this.editAccount("developer.hideFaureciaAptoideBanner", true).subscribe({
            next: () => {
                this.editAccountLocally("developer.hideFaureciaAptoideBanner", true);
            },
        });
    }

    hideiOSBanner() {
        this.editAccount("developer.hideIOSBanner", true).subscribe({
            next: () => {
                this.editAccountLocally("developer.hideIOSBanner", true);
            },
        });
    }

    logout() {
        this.loggedUser.next(null);
        this._cookieService.delete(environment.authorizationTokenCookieName, "/", getDomainCookie(), environment.production);
        window.sessionStorage.setItem("isLogout", "true");
    }

    updateUserLanguage(language: string) {
        const params = { locale: language };
        this.http.patch(`${environment.baseNodeUrlServices}/api/users/${this.loggedUser.value.uid}`, params).subscribe({
            next: (resp: any) => {
                this.languageChanged.next(true);
            },
        });
    }

    sendUrlLog(url: string) {
        this.http.post(`${environment.baseNodeUrlServices}/api/utils/urlLogs`, { url }).subscribe();
    }

    setFeedback(message: string) {
        const url = environment.baseNodeUrlServices + "/api/intercom/conversationMessage";
        return this.http.post(url, { message });
    }

    intercom(value, step: string) {
        if (typeof (<any>window).Intercom !== "undefined") {
            const intercomSettings = {
                app_id: "t3lrdtj5",
                name: this.loggedUser?.value?.name, // Full name
                email: this.loggedUser?.value?.username, // Login Email
                custom_launcher_selector: "#intercom-custom-launcher",
                alignment: "right",
                horizontal_padding: 1,
                vertical_padding: 1,
            };
            if (step === "add_page") {
                intercomSettings["add_app_page_visit"] = value;
            }
            (<any>window).intercomSettings = intercomSettings;
            (<any>window).Intercom("update");
        }
    }

    setlastIntercomUpdate(timestamp: any) {
        this._lastIntercomUpdate = timestamp;
    }

    get lastIntercomUpdate(): any {
        return this._lastIntercomUpdate;
    }
}
