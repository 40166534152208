import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PermissionsService } from "./permissions.service";
import { UserService } from "./user.service";

@Injectable()
export class InitService {
    constructor(private userService: UserService, private router: Router, private permissionService: PermissionsService, public dialog: MatDialog) {}

    init() {
        this.userService.loadUserBasicInformation();
        return new Promise<void>((resolve, reject) => {
            this.userService.loadingUserBasicInformation.subscribe((res) => {
                if (!res) {
                    this.permissionService.allowedPlaces();
                    //if (!window.location.href.split("/").some((r) => this.permissionService.allowedPaths.includes(r)) && !window.location.href.includes("?amazonCheckoutSessionId=")) {
                    if (!window.location.href.split("/").some((r) => this.permissionService.allowedPaths.includes(r))) {
                        this.router.navigate([this.permissionService.allowedPaths[0]]);
                    }
                    resolve();
                }
            });
        });
    }
}
