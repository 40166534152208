import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";
import { UserService } from "@app/core/services/user.service";
import { AccountsService } from "@app/core/services/accounts.service";
import { SelectEntry } from "../select/select.component";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "app-dropdown-accounts",
    templateUrl: "./dropdown-accounts.component.html",
    styleUrls: ["./dropdown-accounts.component.scss"],
    animations: [
        trigger("enterAnimation", [
            transition(":enter", [style({ opacity: 0 }), animate("250ms", style({ opacity: 1 }))]),
            transition(":leave", [style({ opacity: 1 }), animate("250ms", style({ opacity: 0 }))]),
        ]),
    ],
})
export class DropdownAccountsComponent implements OnInit {
    loadingAccountSwitch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    accountsList: SelectEntry[] = [];
    selectedAccount: SelectEntry;

    @Input() isMobile: boolean = false;
    @Input() showDropdown: BehaviorSubject<boolean>;
    @Input() onTopBar: boolean = false;
    @Output() onNavigateBack: EventEmitter<any> = new EventEmitter();

    constructor(private userService: UserService, public accountsService: AccountsService) {}

    ngOnInit() {
        this.accountsService.loadAccountsList().subscribe({
            next: (res: any) => {
                this.accountsService.accountsList = res;
                this.accountsList = res.map((account: any) => {
                    if (account.uid === this.userService.selectedAccountUid) {
                        this.selectedAccount = {
                            uid: account.uid,
                            value: account.uid,
                            label: account.name,
                        };
                    }
                    return {
                        uid: account.uid,
                        value: account.uid,
                        label: account.name,
                    } as SelectEntry;
                });
            },
        });
    }

    switchAccount(event: any) {
        this.loadingAccountSwitch.next(true);
        if (event.value !== this.userService.loggedAccount.value?.uid) {
            this.accountsService.switchAccount(event.value).subscribe({
                next: (res: any) => {
                    this.accountsService.logWithLegalDocsAccepted(res, false);
                    this.accountsService.redirectOnSwitchAccount(res);
                },
                error: () => {
                    this.loadingAccountSwitch.next(false);
                },
            });
        }
    }

    toggleDropdown() {
        this.showDropdown.next(!this.showDropdown.value);
    }

    closeDropdown() {
        this.showDropdown.next(false);
    }

    onBackClick() {
        this.onNavigateBack.emit();
    }
}
