import { environment } from "@env/environment";

export function isClientSideExecution() {
    if (typeof window !== "undefined") {
        return true;
    }

    return false;
}

export function isServerSideExecution() {
    return !isClientSideExecution();
}

export function isCn() {
    if (isClientSideExecution()) {
        if (environment.production && window.location.hostname.endsWith(".cn")) {
            return true;
        }
    }

    return false;
}

export function getPortalUrl() {
    if (isCn()) {
        return environment.basePortalUrl.replace(".io", ".cn");
    }

    return environment.basePortalUrl;
}

export function getPartnersConsoleUrl() {
    if (isCn()) {
        return environment.partnersConsoleUrl.replace(".io", ".cn");
    }

    return environment.partnersConsoleUrl;
}

export function getDomainCookie() {
    if (environment.production && window.location.hostname.endsWith(".cn")) {
        return environment.chinaHostname;
    }

    return environment.domainCookie;
}
