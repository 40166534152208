import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SelectEntry } from "@app/shared/select/select.component";
import { environment } from "@env/environment";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "app-release-schedule",
    templateUrl: "./release-schedule.component.html",
    styleUrls: ["./release-schedule.component.scss"],
})
export class ReleaseScheduleComponent implements OnInit {
    today = new Date(moment().toLocaleString());
    tomorrow = new Date(moment().add(1, "days").toLocaleString());
    maxDate = new Date(moment().add(1, "years").toLocaleString());
    selectedOption: BehaviorSubject<string> = new BehaviorSubject<string>("now");
    hideOptions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    allTimeOptions = [];
    timeOptions: BehaviorSubject<SelectEntry[]> = new BehaviorSubject<SelectEntry[]>(this.allTimeOptions);
    selectedTime = this.timeOptions.value[0];
    selectedDate = this.tomorrow;

    constructor(public dialogRef: MatDialogRef<ReleaseScheduleComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        for (let index = 0; index < 24; index++) {
            if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(index)) {
                this.allTimeOptions.push({
                    label: moment(`0${index}:00`, ["HH:mm"]).format("hh:mm a"),
                    value: `0${index}:00:00`,
                });
                this.allTimeOptions.push({
                    label: moment(`0${index}:30`, ["HH:mm"]).format("hh:mm a"),
                    value: `0${index}:30:00`,
                });
                continue;
            }

            this.allTimeOptions.push({
                label: moment(`${index}:00`, ["HH:mm"]).format("hh:mm a"),
                value: `${index}:00:00`,
            });
            this.allTimeOptions.push({
                label: moment(`${index}:30`, ["HH:mm"]).format("hh:mm a"),
                value: `${index}:30:00`,
            });
        }
    }

    ngOnInit(): void {
        this.selectedOption.next(this.data?.selectedOption);

        if (this.data.hideOptions) {
            this.hideOptions.next(true);
            this.selectedOption.next("specific");
        }
        this.data?.date ? (this.selectedDate = this.data?.date) : (this.selectedDate = this.tomorrow);
        this.isTodaySelected();
        if (this.data?.date && this.data?.time) {
            this.selectedOption.next("specific");
        }
    }

    isTodaySelected() {
        if (moment(this.selectedDate).isSame(moment(), "day")) {
            const now = moment(moment(), "HH:mm:ss");

            const filteredTimeOptions = this.allTimeOptions.filter((time) => {
                const parsedTime = moment(time.value, "HH:mm:ss");

                return parsedTime.isAfter(now);
            });
            this.timeOptions.next(filteredTimeOptions);

            if (this.data.time) {
                if (moment(this.data.time, "HH:mm:ss").isBefore(moment(filteredTimeOptions[0].value, "HH:mm:ss"))) {
                    this.selectedTime = this.timeOptions.value[0];
                } else {
                    this.selectedTime = this.timeOptions.value.filter((d) => d.value === this.data.time.value)[0];
                }
                return;
            }
            this.selectedTime = this.timeOptions.value[0];
            return;
        }

        this.timeOptions.next(this.allTimeOptions);

        this.data?.time ? (this.selectedTime = this.timeOptions.value.filter((d) => d.value === this.data.time.value)[0]) : (this.selectedTime = this.timeOptions.value[0]);
    }

    closeDialogWithOption() {
        this.dialogRef.close({ date: this.selectedDate, time: this.selectedTime, selectedOption: this.selectedOption.value });
    }

    closeDialog() {
        this.dialogRef.close(null);
    }

    onSelectedDateChange(evt) {
        this.selectedDate = evt;
        this.isTodaySelected();
    }

    onSelectedTimeChange(evt) {
        const timeObject = this.timeOptions.value.filter((time) => time.value === evt.value)[0];
        this.selectedTime = timeObject;
        this.data.time = timeObject;
    }

    updateOption(evt) {
        this.selectedOption.next(evt);
    }

    openAppRestrictions() {
        window.open(`${environment.documentationHostname}app-restrictions`, "_blank").focus();
    }
}
