<div class="autocomplete-label" *ngIf="showLabel && label">
    <p class="veryVerySmall pink defaultLineHeight uppercase">{{ label | translate }}:<span *ngIf="mandatory">*</span></p>
</div>
<div class="autocomplete" [class.pointer]="!disabled && !readOnly && !loading">
    <div class="input-widget" [class.disabled]="disabled">
        <input
            #autoCompleteInput
            matInput
            [matAutocomplete]="auto"
            [formControl]="formControl"
            [placeholder]="showPlaceholder ? placeholder : ''"
            [class.disabled]="disabled"
            [class.mobileFilter]="mobileFilter"
            [class.roundedBorders]="roundedBorders"
            [class.error]="showSpecificError || showError"
            [class.noBorderRadiusRight]="noBorderRadiusRight"
            [class.attachOnRight]="attachOnRight"
            [class.readOnly]="readOnly"
            [class.big]="big"
            [class.veryBig]="veryBig"
            [class.showSelectedOptionIcon]="showSelectedOptionIcon && selectedOption"
            [class.bluePlaceholder]="!showSelectedOption"
        />
        <div class="selected-option-icon" *ngIf="showSelectedOptionIcon && selectedOption">
            <div class="img-container">
                <img *ngIf="package" [src]="selectedOption.iconUrl" (error)="imgSelectedPackage.src = 'assets/images/appicon-fallback.svg'" #imgSelectedPackage />

                <img *ngIf="partner || country" [src]="selectedOption.iconUrl" (error)="imgSelectedPartnerOrCountry.src = 'assets/images/unknown-square.svg'" #imgSelectedPartnerOrCountry />
            </div>
        </div>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                <ng-container *ngIf="!hideIcon">
                    <div class="img-container">
                        <img *ngIf="package" [src]="option?.iconUrl" (error)="imgOptionPackage.src = 'assets/images/appicon-fallback.svg'" #imgOptionPackage />
                        <img *ngIf="partner || country" [src]="option?.iconUrl" (error)="imgOptionPartnerOrCountry.src = 'assets/images/unknown-square.svg'" #imgOptionPartnerOrCountry />
                    </div>
                </ng-container>
                <span class="almostBlack veryVerySmall normal inputFontMobileSize" [class.optionLabelFixedWidth]="showSelectedOptionIcon && !mobileFilter">{{ option.label }}</span>
            </mat-option>
        </mat-autocomplete>
    </div>
    <img src="/assets/images/clear_blue_dark.svg" class="clear" [class.clear--error]="error" *ngIf="!disabled && !readOnly && selectedOption && showClear && showSelectedOption" (click)="clear()" />
</div>
<div class="error-box" *ngIf="showError && error">
    <p class="superTiny red thin">
        {{ error }}
    </p>
</div>
