import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: "app-date-picker",
    templateUrl: "./date-picker.component.html",
    styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent {
    @Input() selectedDate: Date = new Date();
    @Output() selectedDateChange = new EventEmitter<Date>();

    @Input() min: Date = new Date(2015, 1, 1);
    @Input() max: Date = new Date();

    @Input() leftAlign: boolean = false;
    @Input() disabled: boolean = false;
    @Input() oneMonthMax: boolean = false;
    @Input() veryBig: boolean = false;

    @ViewChild("inputBox") inputBox: ElementRef;
    @ViewChild("inputArrow") inputArrow: ElementRef;
    @ViewChild("inputText") inputText: ElementRef;
    @ViewChild("calendarDropdown") calendarDropdown: ElementRef;

    showDropdown = false;

    constructor() {}

    initialDate = new Date();

    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }

    closeDropdown() {
        this.showDropdown = false;
    }

    onClickedOutside(event: any) {
        if (
            event.target !== this.inputBox.nativeElement &&
            event.target !== this.inputArrow.nativeElement &&
            event.target !== this.inputText.nativeElement &&
            (!event.target.classList || !event.target.classList.value || !event.target.classList.value.includes("mat-calendar"))
        ) {
            this.closeDropdown();
        }
    }

    updateDates(selectedDate) {
        this.selectedDate = selectedDate;
        this.selectedDateChange.emit(selectedDate);

        this.closeDropdown();
    }
}
