<div class="tablet-version">
    <div class="mobile-navigation-bar-container" [class.hide-shadow]="(isMyAppsRoute | async) && (appListService?.appsList | async)?.length && !(appListService.loadingAppsList | async)">
        <div class="navigation-buttons">
            <div class="button" (click)="navigateTo('dashboard')">
                <img [src]="router.url === '/dashboard' ? '/assets/images/mobile-navigation-bar-dashboard-pink.svg' : '/assets/images/mobile-navigation-bar-dashboard-blue.svg'" />
                <p class="xxxs" [class.pink]="router.url === '/dashboard'" translate>layout.sidebar.dashboard</p>
            </div>
            <div *ngIf="showStatistics | async" class="button" (click)="navigateTo('statistics')">
                <img [src]="router.url === '/statistics' ? '/assets/images/mobile-navigation-bar-statistics-pink.svg' : '/assets/images/mobile-navigation-bar-statistics-blue.svg'" />
                <p class="xxxs" [class.pink]="router.url === '/statistics'" translate>layout.sidebar.statistics</p>
            </div>
            <div *ngIf="showMyApps | async" class="button" (click)="navigateTo('myApps')">
                <img [src]="router.url === '/myApps' ? '/assets/images/mobile-navigation-bar-apps-pink.svg' : '/assets/images/mobile-navigation-bar-apps-blue.svg'" />
                <p class="xxxs" [class.pink]="router.url === '/myApps'" translate>layout.sidebar.myApps</p>
            </div>
            <div *ngIf="showMyWallet | async" class="button" (click)="navigateTo('wallet')">
                <img [src]="router.url === '/wallet' ? '/assets/images/mobile-navigation-bar-wallet-pink.svg' : '/assets/images/mobile-navigation-bar-wallet-blue.svg'" />
                <p class="xxxs" [class.pink]="router.url === '/wallet'" translate>layout.sidebar.myWallet</p>
            </div>
        </div>
    </div>
</div>
