import { LOCATION_INITIALIZED, Location, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, Injectable, Injector, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "@app/core";
import { AuthInterceptor } from "@app/interceptors/auth.interceptor";
import { CacheInterceptor } from "@app/interceptors/cache.interceptor";
import { NavigatingOutsideSubmissionGuard } from "@app/interceptors/navigating.outside.submission.guard";
import { PendingChangesGuard } from "@app/interceptors/pending.changes.guard";
import { SharedModule } from "@app/shared";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { LottieComponent, provideLottieOptions } from "ngx-lottie";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AccountsService } from "./core/services/accounts.service";
import { InitService } from "./core/services/init.service";
import { PermissionsService } from "./core/services/permissions.service";
import { isClientSideExecution } from "./core/services/utils";

export function playerFactory() {
    return import("lottie-web");
}

@Injectable()
export class InternalErrorHandler implements ErrorHandler {
    constructor(private http: HttpClient) {}

    handleError(error) {
        if (environment.local) {
            console.error(error);
        }
        if (isClientSideExecution() && error.stack && !environment.local) {
            this.http.post(`${environment.baseNodeUrlServices}/api/utils/sentry/developers_console`, { error: error.stack }).subscribe({
                next: (resp: any) => {},
            });
        }
    }
}

export function initApp(initService: InitService) {
    return (): Promise<any> => {
        return initService.init();
    };
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                const sessionLanguage = window.localStorage.getItem("usedLanguage");
                const isChineseDomain = environment.name === "china";

                const languageToUse: string = (() => {
                    if (environment.enableTranslations) {
                        if (sessionLanguage) {
                            return sessionLanguage;
                        }

                        if (isChineseDomain) {
                            return "zh_CN";
                        }
                    }

                    return "en_GB";
                })();

                translate.setDefaultLang(languageToUse);
                translate.use(languageToUse).subscribe({
                    next: () => {},
                    error: (err) => {},
                    complete: () => {
                        resolve(null);
                    },
                });
            });
        });
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [CoreModule, BrowserModule, AppRoutingModule, BrowserAnimationsModule, SharedModule.forRoot(), MatSnackBarModule, FormsModule, LottieComponent],
    providers: [
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
        InitService,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [InitService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },
        PermissionsService,
        AccountsService,
        PendingChangesGuard,
        NavigatingOutsideSubmissionGuard,
        {
            provide: ErrorHandler,
            useClass: InternalErrorHandler,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideLottieOptions({
            player: () => import("lottie-web"),
        }),
    ],
})
export class AppModule {}
