import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { TranslateService } from "@ngx-translate/core";

export interface SelectEntry {
    label?: string;
    secondLabel?: string;
    value?: string;
    uid?: string;
    children?: SelectEntry[];
}
@Component({
    selector: "app-select",
    templateUrl: "./select.component.html",
    styleUrls: ["./select.component.scss"],
})
export class SelectComponent {
    @Input() options: SelectEntry[] = [];
    @Input() selectedOption: SelectEntry = null;
    @Output() selectedOptionChange = new EventEmitter<SelectEntry>();
    @Input() panelRightToTheLeft = false;
    @Input() label: string = null;
    @Input() selectedOptionImage: string = null;
    @Input() showLabel: boolean = false;
    @Input() mandatory: boolean = false;
    @Input() white: boolean = false;
    @Input() gray: boolean = false;
    @Input() roundedBlueBorder: boolean = false;
    @Input() roundedWhiteBorder: boolean = false;
    @Input() placeholder: string = null;
    @Input() showScroll: boolean = false;
    @Input() showTinyScroll: boolean = false;
    @Input() disabled: boolean = false;
    @Input() attachToElementOnLeft: boolean = false;
    @Input() noBorderRadiusLeft: boolean = false;
    @Input() mobileFilter: boolean = false;
    @Input() mobilePanel: boolean = false;
    @Input() roundedBorders: boolean = false;
    @Input() veryRoundedBorders: boolean = false;
    @Input() noMaxWidth: boolean = false;
    @Input() small: boolean = false;
    @Input() big: boolean = false;
    @Input() veryBig: boolean = false;
    @Input() withMinWidth: boolean = false;
    @Input() withBigMinWidth: boolean = false;
    @Input() withMediumMinWidth: boolean = false;
    @Input() withSmallMinWidth: boolean = false;
    @Input() keepSmallLabelFontOnMobile: boolean = false;
    @Input() hideArrow: boolean = false;
    @Input() withSmallMaxWidth: boolean = false;
    @Input() withMediumMaxWidth: boolean = false;

    showDropdown: boolean = false;

    @ViewChild("selectElement") selectElement: MatSelect;

    constructor(public translate: TranslateService) {}

    toggle() {
        if (this.disabled) {
            return;
        }

        this.selectElement.open();
    }

    changeSelectedOption(_option: any) {
        if (this.disabled) {
            return;
        }
        this.selectedOption = _option;
        this.selectedOptionChange.emit(this.selectedOption);
    }
}
