import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";
import { APIRequestsService, RequestParameters } from "./api.requests.service";
import { getDomainCookie } from "./utils";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Injectable({
    providedIn: "root",
})
export class AccountsService {
    loggedUser = new BehaviorSubject<any>(null);
    accountsList: any;
    deletionRequestCancelled = new BehaviorSubject<boolean>(false);

    constructor(private requestsService: APIRequestsService, private _cookieService: CookieService, public snackBar: MatSnackBar, private translateService: TranslateService, private router: Router) {}

    setFeedback(message: string) {
        const url = "/api/intercom/conversationMessage";

        const parameters: RequestParameters[] = [];
        parameters.push({ label: "message", data: message });
        return this.requestsService.post(url, parameters);
    }

    loadAccountsList() {
        const url = "/api/accounts";
        return this.requestsService.get(url);
    }

    switchAccount(accountUid) {
        const url = "/api/session/switchAccount";

        const parameters: RequestParameters[] = [];
        parameters.push({ label: "accountUid", data: accountUid });

        return this.requestsService.post(url, parameters);
    }

    logWithLegalDocsAccepted(data, rememberMe) {
        this.buildOurCookies(data, rememberMe);
    }

    buildOurCookies(data: any, rememberMe?: boolean) {
        let expiresIn;
        if (rememberMe) {
            expiresIn = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
        } else {
            expiresIn = new Date(new Date().getTime() + 24 * 60 * 1000); // 24 hours
        }

        this._cookieService.set(environment.authorizationTokenCookieName, data.sessionToken, {
            domain: getDomainCookie(),
            expires: expiresIn,
            secure: environment.production,
        });
    }

    cancelDeletionRequest(id: number) {
        const url = `/api/accounts/deletionRequests/${id}`;
        this.requestsService.delete(url).subscribe({
            next: (res) => {
                this.deletionRequestCancelled.next(true);
                this.snackBar.open(this.translateService.instant("dashboard.deletionRequestedCancel"), null, {
                    duration: 3000,
                    panelClass: ["success-snackbar"],
                    horizontalPosition: "end",
                });
            },
        });
    }

    redirectOnSwitchAccount(data: any) {
        this.router.navigate(["/"]);
        setTimeout(() => {
            window.location.reload();
        }, 200);
    }
}
